import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "contentHeadline",
  class: "flex flex-wrap sm:flex-nowrap pt-5 pb-3 justify-center"
}
const _hoisted_2 = {
  itemprop: "headline",
  class: "mr-1 text-center"
}
const _hoisted_3 = { class: "ml-7" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoreInfo = _resolveComponent("MoreInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, [
        (_ctx.$slots.title)
          ? _renderSlot(_ctx.$slots, "title", { key: 0 })
          : (_ctx.title)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ], 64))
            : _createCommentVNode("", true),
        _createElementVNode("small", _hoisted_3, [
          _renderSlot(_ctx.$slots, "subtitle")
        ])
      ]),
      (_ctx.infoText)
        ? (_openBlock(), _createBlock(_component_MoreInfo, {
            key: 0,
            class: "self-start ml-3 mr-3 mt-2",
            showInfo: _ctx.showInfo,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfo = !_ctx.showInfo))
          }, null, 8, ["showInfo"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.showInfo ? 'max-h-96 overflow-y-auto' : 'overflow-hidden max-h-0', "max-w-screen mx-auto transition transition-ease-in duration-500 transition-maxHeight"])
    }, [
      _createElementVNode("div", {
        class: "bg-grey-verylight max-w-screen-md mx-auto rounded p-7 my-3",
        innerHTML: _ctx.infoText
      }, null, 8, _hoisted_4)
    ], 2)
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center flex-wrap w-full p-0" }
const _hoisted_2 = { class: "md:order-2 order-1 w-full md:w-min flex-grow my-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlayButton, {
      class: "md:order-1 order-2 my-2",
      text: _ctx.textLeft,
      arrowType: "back",
      textFirst: false,
      internalLink: _ctx.leftLink,
      disabled: !_ctx.leftLink
    }, null, 8, ["text", "internalLink", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.middleLink)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.middleLink
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PrimaryButton, { class: "w-72 mb-2" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.textMiddle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PlayButton, {
      class: "md:order-3 order-2 my-2",
      text: _ctx.textRight,
      arrowType: "forward",
      textFirst: true,
      internalLink: _ctx.rightLink,
      disabled: !_ctx.rightLink
    }, null, 8, ["text", "internalLink", "disabled"])
  ]))
}